import React from 'react';
import {
    Row, Col, DatePicker, Form, Input,
    Divider, Space, Button, Table, notification, ConfigProvider,
    InputNumber, TimePicker, Image, Modal, message
} from 'antd';
import moment from 'moment';
import { path } from "../path";
// import Print from './print';
import Menu from '../Components/Menu'
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';



export default class Phase1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            date: '',
            location: '',
            detail: '',
            month: '',
            datestr: '',
            dataSource: [],
            date1: '',
            title: '',
            count: '1',
            menuid: '',
            printurl: '',
            detail1: '',
            startTime: '09:00:01',
            endTime: '12:00:01',
            isModalVisible1: false,
            isModalVisible: false,
            image: 'https://oa.haosen.com.cn:8644/img/notimage.jpg',
            id: "",
            disabled: false
        }
        this.child = React.createRef();
    }


    dateOnChange = (date, dateString) => {
        if (dateString !== '') {
            const myDate = new Date(date);

            //console.log(dateString)
            const Year = myDate.getFullYear();
            let Month = myDate.getMonth() + 1;

            Month = Month.toString().length === 1 ? '0' + Month.toString() : Month
            const ym = Year.toString() + Month.toString()
            this.setState({
                datestr: dateString,
                date: dateString,
                month: ym
            })
        }
    }

    componentWillMount() {

        const myDate = new Date();

        const Year = myDate.getFullYear();
        let Month = myDate.getMonth() + 1;

        Month = Month.toString().length === 1 ? '0' + Month.toString() : Month
        const ym = Year.toString() + Month.toString()
        this.loadMenu(ym)
        this.getbookdinnerList()
        this.setState({
            date: moment(),
            datestr: moment().format("YYYY-MM-DD"),
            location: this.props.location,
            month: ym,
            date1: moment().format("YYYY-MM-DD"),
        })
    }

    menuSubmit = () => {

        if (this.state.datestr === '') {
            notification['info']({
                message: '提示',
                description:
                    '日期必填！',
            });
            return;
        }
        if (this.state.detail === '') {
            notification['info']({
                message: '提示',
                description:
                    '菜单必填！',
            });
            return;
        }
        const data = {
            token: this.state.token,
            location: this.props.location,
            month: this.state.month,
            detail: this.state.detail,
            date: this.state.datestr,
            creater: sessionStorage.getItem('lastname')
        }
        fetch(path + 'Menu/saveMenus', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({ data }),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {

                let result = JSON.parse(data)
                if (result.message === "超出授权时间！") {
                    message.error(result.message)
                    let url = window.location.protocol + "//" + window.location.host + '/login.html';
                    window.location.replace(url);
                    return;
                }
                // alert(result.message);
                notification['info']({
                    message: '提示',
                    description:
                        result.message
                });
                this.loadMenu(this.state.month)
                this.setState({
                    detail: ""
                })

            })
    }

    menuonClickRow = (recode) => {
         // console.log(recode)
        // console.log(this)
        this.setState({
            detail: recode.detail,
            date: moment(recode.datestr).format('YYYY-MM-DD'),
            datestr: recode.datestr,
            month: recode.month
        })

    }

    menuonClickRow1 = (recode) => {
       //  console.log(recode)
        // console.log(this)
        this.setState({
            detail1: recode.detail,
            date1: moment(recode.date).format('YYYY-MM-DD'),
            title: recode.title,
            detail1: recode.detail,
            count: recode.count,
            startTime: recode.startTime,
            endTime: recode.endTime,
            date: moment(recode.date1),
            image: recode.image,
            menuid: recode.menuid,
            id: recode.id,
        })

    }


    loadMenu = (month) => {
        const token = sessionStorage.getItem("token");

        const data = {
            token: token,
            location: this.props.location,
            month: month
        }
        fetch(path + 'Menu/getMonthMenusDetail', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({ data }),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {

                let result = JSON.parse(data)
                //alert(result.message);
                if (result.message === "超出授权时间！") {
                    message.error(result.message)
                    let url = window.location.protocol + "//" + window.location.host + '/login.html';
                    window.location.replace(url);
                    return;
                }
                this.setState({
                    dataSource: JSON.parse(result.data),
                    token: token,
                    month: month,
                    date: moment()
                })

            })
    }

    detailChange = (e) => {
        // console.log(e)
        this.setState({
            detail: e.target.value
        })
    }

    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }
    ModalCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }

    ModalhandleOk = () => {
        // console.log(this.child.state.selectedRows)
        const selectedRows = this.child.state.selectedRows
        console.log(selectedRows)
        this.setState({
            isModalVisible: false,
            menuid: selectedRows.id,
            title: selectedRows.title,
            image: selectedRows.image,
            detail1: selectedRows.detail,
            disabled: true
        })
    }


    detai1lChange = (e) => {
        this.setState({
            detai1: e.target.value
        })
    }

    date1OnChange = (date, dateString) => {
        //
        this.setState({
            date1: dateString
        })
    }

    titlelChange = (e) => {
        this.setState({
            titlel: e.target.value
        })
    }

    countChange = (value) => {
        this.setState({
            count: value
        })
    }

    startTimeChange = (time, timeString) => {

        //console.log(time, timeString)
        if (timeString !== '') {
            this.setState({
                startTime: timeString + ":01"
            })
        }
    }

    endTimeChange = (time, timeString) => {
        // console.log(time, timeString)
        if (timeString !== '') {
            this.setState({
                endTime: timeString + ":01"
            })
        }
    }


    getbookdinnerList = () => {
        const data = {
            location: this.props.location,
            token: sessionStorage.getItem("token")
        }

        fetch(path + 'Order/getbookdinnerList', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({ data }),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {

                let result = JSON.parse(data)
                //console.log(result);
                if (result.message === "超出授权时间！") {
                    message.error(result.message)
                    let url = window.location.protocol + "//" + window.location.host + '/login.html';
                    window.location.replace(url);
                    return;
                }
                this.setState({
                    dataSource1: JSON.parse(result.data),

                })

            })
    }

    release = () => {
        console.log(this.state.endTime)
        const data = {
            location: this.props.location,
            token: sessionStorage.getItem("token"),
            title: this.state.title,
            detail: this.state.detail1,
            count: this.state.count,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            date: this.state.date1,
            image: this.state.image,
            menuid: this.state.menuid,
            id: this.state.id,
        }
        //console.log(this.state.count)
        if (this.state.title.length === 0) {
            message.error('请输入菜品名称!!');
            return
        }
        if (this.state.detail1.length === 0) {
            message.error('请输入菜品配方!!');
            return
        }
        if (this.state.date.length === 0) {
            message.error('请输入日期!');

            return
        }
        if (this.state.startTime.length === 0) {
            message.error('请输入开始时间!');

            return
        }
        if (this.state.endTime.length === 0) {
            message.error('请输入结止时间!');

            return
        }
        if (this.state.count === '') {
            message.error('请输入数量!');

            return
        }
        fetch(path + 'Order/savebookdinner', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({ data }),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {
                // console.log(data)
                let result = JSON.parse(data)
                // alert(result.message);
                if (result.message === "超出授权时间！") {
                    message.error(result.message)
                    let url = window.location.protocol + "//" + window.location.host + '/login.html';
                    window.location.replace(url);
                    return;
                }
                notification['info']({
                    message: '提示',
                    description:
                        result.message
                });
                this.setState({
                    title: '',
                    detail1: '',
                    count: '1',
                    startTime: moment('09:00:01', 'HH:mm'),
                    endTime: moment('12:00:01', 'HH:mm'),
                    date: moment(),
                    image: 'https://oa.haosen.com.cn:8644/img/notimage.jpg',
                    menuid: '',
                    id: '',
                })
                this.getbookdinnerList()
            })
    }


    reset = () => {
        this.setState({
            title: '',
            detail1: '',
            count: '1',
            startTime: moment('09:00:01', 'HH:mm'),
            endTime: moment('12:00:01', 'HH:mm'),
            date: moment(),
            image: 'https://oa.haosen.com.cn:8644/img/notimage.jpg',
            menuid: '',
            id: '',
            date1: moment()
        })
    }

    reset1 = () => {
        this.setState({
            detail: "",
            date: moment()
        })
    }

    handleCancel = () => {
        this.setState({
            isModalVisible1: false,
        })
    }
 


    render() {
        const { TextArea } = Input;

        const columns = [
            // {
            //     title: 'id',
            //     dataIndex: 'id',
            //     width: 70,
            //     render: (text: string) => <a>{text}</a>,
            // },
            {
                title: '日期',
                dataIndex: 'datestr',
                width: 90,
                render: (text) => <a>{text}</a>,
            },
            {
                title: '食堂',
                dataIndex: 'location',
                width: 70
            },
            {
                title: '菜单',
                dataIndex: 'detail',
                width: 470
            },
            {
                title: '月份',
                dataIndex: 'month',
                width: 70
            },
            {
                title: '创建人',
                dataIndex: 'creater',
                width: 70
            },
        ];
        const columns1 = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 50,
                render: (text) => <a>{text}</a>,
            },
            {
                title: '菜品名称',
                dataIndex: 'title',
                width: 100,

            },
            {
                title: '菜品配方',
                dataIndex: 'detail',
                width: 160,

            },
            {
                title: '可订数量',
                dataIndex: 'count',
                width: 68,
            },
            {
                title: '开始时间',
                dataIndex: 'startTime',
                width: 60,
            },
            {
                title: '结止时间',
                dataIndex: 'endTime',
                width: 60,
            }, {
                title: '日期',
                dataIndex: 'date',
                width: 87,
            }, {
                title: '位置',
                dataIndex: 'location',
                width: 60,
            }, {
                title: '菜单ID',
                dataIndex: 'menuid',
                width: 60,
            },
            {
                title: '图片',

                width: 120,
                dataIndex: 'image',
                key: 'image',
                render: (record) => <img src={record} alt="" width="100px" />
            },
            {
                title: 'Action',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (record) => <a onClick={() => {
                    console.log(record)

                    this.setState({
                        isModalVisible1: true,
                       // printurl: 'http://192.168.7.60:8080/WebReport1/ReportServer?reportlet=xcx/dc.cpt&order_id=' + record.id  
                         printurl: 'http://data.haosen.com.cn:8080/WebReport1/ReportServer?reportlet=xcx/dc.cpt&order_id=' + record.id+  "&"+Math.random()*10
                    })
                }}>打印</a>,
            },
        ]

        return (
            <div>
                <Row>
                    <Col span={8}>
                        <Divider orientation="left">{this.state.location}食堂菜单</Divider>
                        <Form.Item
                            label="日期"
                            //  name="date"

                            disabled={this.state.disabled}
                            rules={[{ required: true, message: '请选择日期' }]}
                            placeholder="请输入日期"
                        ><ConfigProvider locale={locale}>
                                <DatePicker onChange={this.dateOnChange}
                                    //initialValues={moment(this.state.date)}
                                    defaultValue={moment(this.state.date)}
                                    format={'YYYY-MM-DD'}
                                    value={this.state.date ? moment(this.state.date, 'YYYY-MM-DD') : ''}
                                /> </ConfigProvider>
                            <Space>
                                <Input value={this.state.month} disabled
                                    defaultValue={this.state.month}
                                    style={{ width: 100 }} /> </Space>
                        </Form.Item>
                        <Form.Item
                            label="地点"
                            // name="location"
                            rules={[{ required: true, message: '请选择地点' }]}
                        >
                            <Input value={this.state.location} disabled
                                defaultValue={this.state.location}
                                style={{ width: 270 }} />
                        </Form.Item>
                        <Form.Item
                            label="菜单"
                            // name="menu"

                            rules={[{ required: true, message: '请选择菜单' }]}
                        >

                            <TextArea rows={10} value={this.state.detail} onChange={this.detailChange}

                                placeholder="请输入菜单" style={{ width: 270 }} />

                        </Form.Item>

                        <Button type="primary" htmlType="submit" onClick={this.menuSubmit}>
                            提交
                        </Button> &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Space>
                            <Button type="primary" onClick={this.reset1}>
                                重置
                            </Button></Space>
                    </Col>
                    <Col span={16}>
                        <Divider orientation="left">{this.state.location}食堂菜单明细</Divider>
                        <Table
                            dataSource={this.state.dataSource}
                            columns={columns}
                            rowKey={record => record.id}
                            scroll={{ y: 300, x: 1090 }}
                            pagination={{ pageSize: 4 }}

                            onRow={record => {
                                return {
                                    onClick: event => {
                                        this.menuonClickRow(record)
                                    }, // 点击行
                                    onDoubleClick: event => {
                                    },
                                    onContextMenu: event => {
                                    },
                                    onMouseEnter: event => {
                                    }, // 鼠标移入行
                                    onMouseLeave: event => {
                                    },
                                };
                            }}
                        />
                    </Col>

                </Row>

                <Row>

                    <Divider orientation="left">{this.state.location}订餐发布</Divider>

                    <Col span={6}>
                        <Form.Item
                            label="菜品名称"
                            // name="title"
                            rules={[{ required: true, message: '请输入菜品名称!' }]}
                        >
                            <Input value={this.state.title} onChange={this.titlelChange}
                                style={{ width: 180 }} placeholder="请输入餐名" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="发布食堂"
                            //   name="location"
                            rules={[{ required: true, message: '发布食堂!' }]}
                        >
                            <Input value={this.state.location} disabled
                                defaultValue={this.state.location}
                                style={{ width: 180 }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="发布日期"
                            //  name="location"
                            rules={[{ required: true, message: '发布日期!' }]}
                        ><ConfigProvider locale={locale}>
                                <DatePicker onChange={this.date1OnChange}
                                    //initialValues={moment(this.state.date)}
                                    defaultValue={moment(this.state.date1)}
                                    format={'YYYY-MM-DD'}
                                    disabled={this.state.disabled}
                                    value={this.state.date1 ? moment(this.state.date1, 'YYYY-MM-DD') : ''}
                                /> </ConfigProvider>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="可订数量"
                            //name="count"
                            rules={[{ required: true, message: '请输入可订数量!' }]}
                        >
                            <InputNumber min={1} max={1000} defaultValue={1}
                                value={this.state.count} onChange={this.countChange}
                                style={{ width: 180 }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="开始时间"
                            //  name="startTime"
                            rules={[{ required: true, message: '请输入开始时间!' }]}
                        >
                            <TimePicker 
                                 defaultValue={moment(this.state.startTime, 'HH:mm')} format={'HH:mm'}
                                value={moment(this.state.startTime, 'HH:mm')}
                              
                                onChange={this.startTimeChange}></TimePicker>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="结束时间"
                            //name="endTime"
                            rules={[{ required: true, message: '请输入结束时间!' }]}
                        >
                            <TimePicker
                                defaultValue={moment(this.state.endTime, 'HH:mm')} format={'HH:mm'}
                                value={moment(this.state.endTime, 'HH:mm')}
                           
                                onChange={this.endTimeChange}></TimePicker>
                        </Form.Item>
                    </Col>


                    <Col span={6}>
                        <Form.Item
                            label="配方"
                            // name="menu"
                            rules={[{ required: true, message: '请选择菜单配方' }]}
                        >
                            <TextArea rows={5} value={this.state.detail1} onChange={this.detai1lChange}
                                placeholder="请输入菜单配方" style={{ width: 200 }} />

                        </Form.Item>
                    </Col>
                    <Col span={6}>

                        <Image

                            width={200}
                            src={this.state.image}
                        />
                    </Col>
                    <Col span={24}>
                        <Button type="primary" onClick={this.showModal}>选择菜单</Button>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={this.release}>提交发布</Button>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={this.reset}>重置</Button>
                        <div style={{ 'whitespace': 'pre-wrap' }}> {`\n`}</div>
                        <Table dataSource={this.state.dataSource1} columns={columns1}
                            rowKey={record => record.id} pagination={{ pageSize: 6 }}

                            onRow={record => {
                                return {
                                    onClick: event => {
                                        this.menuonClickRow1(record)
                                    }, // 点击行
                                    onDoubleClick: event => {
                                    },
                                    onContextMenu: event => {
                                    },
                                    onMouseEnter: event => {
                                    }, // 鼠标移入行
                                    onMouseLeave: event => {
                                    },
                                };
                            }}
                        />
                    </Col>
                </Row>


                <Modal title="选择菜单模板" visible={this.state.isModalVisible}
                    onOk={this.ModalhandleOk} onCancel={this.ModalCancel}
                    width={1300}>
                    <Menu parent={this} onRef={(ref) => {
                        this.child = ref;
                    }} />
                </Modal>

                <Modal title="打印" visible={this.state.isModalVisible1} width={1300}
                    footer={null} onCancel={this.handleCancel}>
                      
                    <iframe
                        title="print"
                        id="reportFrame"
                        src={this.state.printurl}
                        sandbox={"allow-modals allow-forms allow-popups allow-scripts "+
                       "  allow-same-origin  "}
                        frameBorder="0"
                        style={{ width: '100%', border: '0px', height: '700px' }}
                        scrolling="auto"
                    />

                </Modal>
            </div>
        );
    }
}