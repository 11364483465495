import React from 'react';
import {path} from './path'
import {PageHeader, Button} from 'antd';
import Main from './Components/main'

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: '',
            name: ''
        }
    }


    componentWillMount() {
        const token = sessionStorage.getItem("token");
        let url = window.location.protocol + "//" + window.location.host + '/login.html';
        if (token === null || token === '') {

            window.location.replace(url);
            return;
        }
        this.setState({
            token
        })
        fetch(path + 'Login/checkLogin', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data: token}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {
                let result = JSON.parse(data)
                if (result.code === 'error') {
                    alert(result.message);

                    window.location.replace(url);
                }
            })
    }

    getChildrenMsg = (result, name) => {

        this.setState({
            name: name
        })

    }

    exitClick = () => {
        sessionStorage.clear();
        let url = window.location.protocol + "//" + window.location.host + '/login.html';
        window.location.replace(url);
    }

    render() {
        return (
            <div className="container">
                <PageHeader
                    className="site-page-header"
                    onBack={() => null}
                    title="豪森员工餐后台管理"
                    subTitle={this.state.name}
                    extra={[

                        <Button key="1" type="text" onClick={this.exitClick}>
                            退出系统
                        </Button>,
                    ]}
                />,
                <Main parent={this}/>

            </div>
        );
    }
}