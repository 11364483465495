import React, {Component} from 'react'
import {Tabs} from 'antd';
import {path} from "../path";
import Phase1 from './Phase1'
//import Phase2 from './Phase2'
import Setting from './Setting'

export default class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: []
        }

    }

    componentWillMount() {


        const token = sessionStorage.getItem("token");
        fetch(path + 'Login/getAuthority', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
                data: token,

            }),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {
                let result = JSON.parse(data)
                if (result.code === 'success') {
                    const authority = JSON.parse(result.data)
                    // console.log( )
                    sessionStorage.setItem("lastname", authority[0].name);
                    this.props.parent.getChildrenMsg(this, '-' + authority[0].name)
                    this.setState({
                        location: JSON.parse(authority[0].authority)
                    })
                }

            })
    }

    callback = (key) => {
        console.log(key);
    }


    render() {
        const {TabPane} = Tabs;

        return (
            <div>
                <Tabs onChange={this.callback}>
                    {this.state.location.map(pane => {
                            if (pane.key === "1") {
                                return (<TabPane tab={pane.location} key={pane.key}>
                                    <Phase1 location={pane.location}/>
                                </TabPane>)
                            }
                            if (pane.key === "2") {
                                return (<TabPane tab={pane.location} key={pane.key}>
                                    <Phase1 location={pane.location}/>
                                </TabPane>)
                            }
                            return (
                                <TabPane tab={pane.location} key={pane.key}>
                                    <Setting/>
                                </TabPane>
                            )
                        }
                    )}
                </Tabs>
            </div>
        );
    }
}