import React from 'react';
import {
    Row, Col, Divider, Input, Button, notification,
    Form, Checkbox, Table, Modal, Space
} from 'antd';
import {path} from "../path";
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';

export default class Setting extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            loginid: '',
            name: '',
            password: '',
            authority: [],
            dataSource: [],
            token: '',
            checked1: false,
            checked2: false,
            checkeds: false,
            userid: '',
            hrid: '',
            userName: '',
            department: '',
            mobile: '',
            openId: '',
            status: false,
            subcompany: '',
            checked: false,
            dataSource1: []
        })
    }

    oneOnChange = (e) => {

        if (e.target.checked === true) {
            this.setState({
                authority: [...this.state.authority, {"location": "一期", "key": "1"}],
                checked1: e.target.checked,
            })
        } else {
            const list = [...this.state.authority];
            let tickerIndex = 0;
            for (var i = 0; i < list.length; i++) {
                if (list[i].key === '1')
                    tickerIndex = i;
                break;
            }
            list.splice(tickerIndex, 1)
            this.setState({
                authority: list,
                checked1: e.target.checked,
            })
        }

    }

    twoOnChange = (e) => {

        if (e.target.checked === true) {
            this.setState({
                authority: [...this.state.authority, {"location": "三期", "key": "2"}],
                checked2: e.target.checked,
            })
        } else {

            const list = [...this.state.authority];
            let tickerIndex = 0;
            for (var i = 0; i < list.length; i++) {
                if (list[i].key === '1')
                    tickerIndex = i;
                break;
            }
            list.splice(tickerIndex, 1)
            this.setState({
                authority: list,
                checked2: e.target.checked,
            })
        }

    }

    setOnChange = (e) => {
        //  console.log('checked = ', e.target.checked);
        if (e.target.checked === true) {
            this.setState({
                authority: [...this.state.authority, {"location": "设置", "key": "3"}],
                checkeds: e.target.checked,
            })
        } else {
            const list = [...this.state.authority];
            let tickerIndex = 0;
            for (var i = 0; i < list.length; i++) {
                if (list[i].key === '1')
                    tickerIndex = i;
                break;
            }
            list.splice(tickerIndex, 1)
            this.setState({
                authority: list,
                checkeds: e.target.checked,
            })
        }

    }

    componentWillMount() {
        this.loadUserTable()
        this.loadEmployeeList()
    }

    loadUserTable = () => {
        const token = sessionStorage.getItem("token");
        this.setState({
            token
        })
        const data = {
            token: token,
        }
        fetch(path + 'User/getUser', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {
                // console.log(data)
                let result = JSON.parse(data)
                this.setState({
                    dataSource: result
                })
            })
    }


    userSubmit = () => {
        const key = 'updatable';
        if (this.state.loginid === '') {
            //  alert("登录ID必填！")
            notification.open({
                key,
                message: '警告',
                description: '登录ID必填！',
                duration: 2
            });
            return;
        }
        if (this.state.name === '') {
            // alert("姓名必填！")
            notification.open({
                key,
                message: '警告',
                description: '姓名必填！',
                duration: 2
            });
            return;
        }
        if (this.state.password === '') {
            //alert("密码必填！")
            notification.open({
                key,
                message: '警告',
                description: '密码必填！',
                duration: 2
            });
            return;
        }
        if (this.state.authority === '') {
            //alert("权限必填！")

            notification.open({
                key,
                message: '警告',
                description: '权限必填！',
                duration: 2
            });
            return;
        }
        const data = {
            token: this.state.token,
            loginid: this.state.loginid,
            name: this.state.name,
            password: this.state.password,
            authority: this.state.authority,
        }
        fetch(path + 'User/saveLogin', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {
                //console.log(data)
                let result = JSON.parse(data)
                //alert(result.message);
                notification['info']({
                    message: '提示',
                    description:
                    result.message,
                });
                this.setState({
                    loginid: "",
                    name: "",
                    password: "",
                    authority: [],
                    checked1: false,
                    checked2: false,
                    checkeds: false,
                })
                this.loadUserTable()
            })
    }

    loginChange = (e) => {


        this.setState({
            loginid: e.target.value
        })
    }

    nameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    passwordChange = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    rowOnclick(text) {
        //console.log(text)
        const data = {
            token: this.state.token,
            loginid: text
        }
        fetch(path + 'User/getUserDetail', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {

                let result = JSON.parse(data)
                //   console.log(result)

                this.setState({
                    loginid: text,
                    name: result[0].name,
                })

            })
    }


    userDel = () => {
        const key = 'updatable';
        if (this.state.loginid === '') {
            //  alert("登录ID为空！")
            notification.open({
                key,
                message: '警告',
                description: '登录ID为空',
                duration: 2
            });
            return;
        }
        const data = {
            token: this.state.token,
            loginid: this.state.loginid
        }
        Modal.confirm({
            title: '确认删除',

            content: '确认删除' + this.state.name + "吗？",
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                fetch(path + 'User/delUserDetail', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    body: JSON.stringify({data}),
                    mode: 'cors',
                    cache: 'default'
                })
                    .then(res => res.json()
                    )
                    .then((data) => {
                        //console.log(data)
                        let result = JSON.parse(data)
                        //alert(result.message);
                        notification['info']({
                            message: '提示',
                            description:
                            result.message,
                        });
                        this.setState({
                            loginid: "",
                            name: "",
                            password: "",
                            authority: [],
                            checked1: false,
                            checked2: false,
                            checkeds: false,
                        })
                        this.loadUserTable()
                    })
            }
            ,
            onCancel() {

            },
        });
    }


    loadEmployeeList = () => {
        const token = sessionStorage.getItem("token");

        const data = {token}
        fetch(path + 'User/getEmployeeList', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {
                //console.log(data)
                let result = JSON.parse(data)
                //console.log(result.data);
                this.setState({
                    dataSource1: JSON.parse(result.data),
                    token
                })

            })
    }

    onClickRow = (record) => {
        //  console.log(record)
        this.setState({
            userid: record.loginId,
            hrid: record.hrid,
            userName: record.userName,
            department: record.department,
            mobile: record.mobile,
            openId: record.openId,
            status: record.status,
            subcompany: record.subcompany,
            checked: record.checked,
        })
    }
    checkedChange = (e) => {
        //console.log(e)
        this.setState({
            checked: e.target.checked
        })
    }
    statusonChange = (e) => {
        //console.log(e)
        this.setState({
            status: e.target.checked
        })
    }

    viewCheck = () => {
        const token = sessionStorage.getItem("token");

        const data = {token}
        fetch(path + 'User/getUserCheck', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {

                if (data.length > 2) {
                    //  console.log(data.length)
                    let result = JSON.parse(data)
                    //console.log(result);
                    this.setState({
                        dataSource1: result,
                    })
                } else {
                    this.setState({
                        dataSource1: []
                    })
                }
            })
    }


    viewAll = () => (
        this.loadEmployeeList()
    )

    updateStatus = () => {
        const data = {
            token: this.state.token,
            loginid: this.state.userid,
            status: this.state.status,
            checked: this.state.checked
        }
        fetch(path + 'User/updateStatus', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {

                let result = JSON.parse(data)
                // console.log(result)
                // alert(result.message)
                notification['info']({
                    message: '提示',
                    description:
                    result.message,
                });
                this.loadEmployeeList()
                this.setState({
                    userid: '',
                    hrid: '',
                    userName: '',
                    department: '',
                    mobile: '',
                    openId: '',
                    status: false,
                    subcompany: '',
                    checked: false,
                })
            })
    }


    employeeDel = () => {
        if (this.state.userid === '') {
            //   alert("员工为空！")
            notification['info']({
                message: '警告',
                description:
                    '员工为空!',
            });
            return;
        }
        const data = {
            token: this.state.token,
            loginid: this.state.userid
        }
        Modal.confirm({
            title: '确认删除',

            content: '确认删除' + this.state.userName + "吗？",
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                fetch(path + 'User/deleteEmployee', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    body: JSON.stringify({data}),
                    mode: 'cors',
                    cache: 'default'
                })
                    .then(res => res.json()
                    )
                    .then((data) => {
                        //console.log(data)
                        let result = JSON.parse(data)
                        //alert(result.message);
                        notification['info']({
                            message: '提示',
                            description:
                            result.message,
                        });
                        this.setState({
                            userid: '',
                            hrid: '',
                            userName: '',
                            department: '',
                            mobile: '',
                            openId: '',
                            status: false,
                            subcompany: '',
                            checked: false,
                        })
                        this.loadEmployeeList()
                    })
            }
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        查询
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                        重置
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        过滤
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };

    render() {

        const columns = [
            {
                title: '登录ID',
                dataIndex: 'loginid',
                key: 'loginid',
                width: 80,
                render: (text) =>
                    <a onClick={this.rowOnclick.bind(this, text)}
                    >{text} </a>,

            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                width: 80
            },
            {
                title: '密码',
                dataIndex: 'password',
                key: 'password',
                width: 150
            }, {
                title: '权限',
                dataIndex: 'authority',
                key: 'authority',
                width: 450
            },

        ];

        const columns1 = [
            {
                title: '工号',
                dataIndex: 'loginId',
                key: 'loginId',
                width: 80,
                ...this.getColumnSearchProps('loginId'),
                render: (text) =>
                    <a>{text}  </a>,
            },
            {
                title: '姓名',
                dataIndex: 'userName',
                key: 'userName',
                width: 80,
                ...this.getColumnSearchProps('userName'),
            },
            {
                title: '部门',
                dataIndex: 'department',
                key: 'department',
                width: 130
            },
            {
                title: '分公司',
                dataIndex: 'subcompany',
                key: 'subcompany',
                width: 150
            },
            {
                title: '手机',
                dataIndex: 'mobile',
                key: 'mobile',
                width: 90
            },
            {
                title: 'openid',
                dataIndex: 'openId',
                key: 'openId',
                width: 150
            },

        ];
        return (
            <div>

                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Divider orientation="left">用户信息&权限</Divider>
                        <Form.Item label="登录ID">
                            <Input placeholder="请输入登录ID" value={this.state.loginid} onChange={this.loginChange}/>
                        </Form.Item>
                        <Form.Item label="姓    名">
                            <Input placeholder="请输入姓名" value={this.state.name} onChange={this.nameChange}/>
                        </Form.Item>
                        <Form.Item label="密    码" rules={[{required: true, message: '请输入密码!'}]}>
                            <Input.Password placeholder="请输入密码"
                                            value={this.state.password}

                                            onChange={this.passwordChange}/>
                        </Form.Item>
                        <Form.Item label="权限">
                            <Checkbox onChange={this.oneOnChange} checked={this.state.checked1}>一期</Checkbox>
                            <Checkbox onChange={this.twoOnChange} checked={this.state.checked2}>三期</Checkbox>
                            <Checkbox onChange={this.setOnChange} checked={this.state.checkeds}>设置</Checkbox>
                        </Form.Item>
                        <Button type="primary" htmlType="submit" onClick={this.userSubmit}>
                            提交
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" htmlType="submit" onClick={this.userDel}>
                            删除
                        </Button>
                    </Col>
                    <Col className="gutter-row" span={16}>
                        <Divider orientation="left">用户列表</Divider>
                        <Table dataSource={this.state.dataSource} columns={columns}
                               rowKey={record => record.id}
                               pagination={{pageSize: 3}} scroll={{y: 240, x: 1200}}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Divider orientation="left">员工信息</Divider>
                        <Form.Item label="工号">
                            <Input
                                //  placeholder="请输入工号"
                                value={this.state.userid} disabled/>
                        </Form.Item>
                        <Form.Item label="姓名">
                            <Input
                                //  placeholder="请输入姓名"
                                value={this.state.userName} disabled/>
                        </Form.Item>
                        <Form.Item label="部门">
                            <Input
                                //placeholder="请输入部门"
                                value={this.state.department}
                                disabled/>
                        </Form.Item>
                        <Form.Item label="分公司">
                            <Input
                                //placeholder="请输入分公司"
                                value={this.state.subcompany}
                                disabled/>
                        </Form.Item>
                        <Form.Item label="openid">
                            <Input value={this.state.openId}
                                   disabled/>
                        </Form.Item>
                        <Form.Item label="状态">
                            <Checkbox onChange={this.statusonChange} checked={this.state.status}>有效</Checkbox>
                            <Checkbox onChange={this.checkedChange} checked={this.state.checked}>审核</Checkbox>
                        </Form.Item>
                        <Button type="primary" htmlType="submit" onClick={this.updateStatus}>
                            提交
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" htmlType="submit" onClick={this.employeeDel}>
                            删除
                        </Button>
                    </Col>
                    <Col className="gutter-row" span={16}>
                        <Divider orientation="left">员工列表</Divider>
                        <Space style={{marginBottom: 16}}>
                            <Button onClick={this.viewCheck}>查看待审</Button>
                            <Button onClick={this.viewAll}>查看全部</Button>
                        </Space>
                        <Table dataSource={this.state.dataSource1} columns={columns1}
                               rowKey={record => record.id}
                               scroll={{y: 240, x: 1090}}
                               pagination={{pageSize: 8}}
                               onRow={record => {
                                   return {
                                       onClick: event => {
                                           this.onClickRow(record)
                                       }, // 点击行
                                       onDoubleClick: event => {
                                       },
                                       onContextMenu: event => {
                                       },
                                       onMouseEnter: event => {
                                       }, // 鼠标移入行
                                       onMouseLeave: event => {
                                       },
                                   };
                               }}
                        />

                    </Col>
                </Row>
            </div>
        )
    }
}