import React, {Component} from 'react'
import {
    Divider, Row, Col, Form, Input, Modal, Upload,
    Image, Button, Space, Table, notification, message
} from 'antd';
import {path} from "../path";
import moment from "moment";

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            location: '',
            detail: '',
            id: '',
            disabled: true,
            dataSource: [],
            token: "",
            image: '',
            selectedRows:[]
        }
    }

    componentWillMount() {
        // console.log()
        this.props.onRef(this);
        this.setState({
            location: this.props.parent.state.location,
            token: this.props.parent.state.token,
            image: 'https://oa.haosen.com.cn:8644/img/notimage.jpg'
        })
        this.loadTemplate()

    }


    loadTemplate = () => {
        const data = {
            token: this.props.parent.state.token,
            location: this.props.parent.state.location,
        }
        fetch(path + 'Template/getTemplateList', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {

                let result = JSON.parse(data)
                if(result.message==="超出授权时间！")
                {
                    message.error(result.message)
                    let url = window.location.protocol + "//" + window.location.host + '/login.html';
                    window.location.replace(url);
                    return;
                }
                // console.log(result)
                this.setState({
                    dataSource: result
                })
            })
    }

    rowSelection = (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
    })

    menuonClickRow = (recode) => {
        // console.log(e)
        // console.log(this)
        this.setState({
            detail: recode.detail,
            id: recode.id,
            image: recode.image,
            location: recode.location,
            title: recode.title,
            disabled: false
        })
    }

    reset = () => {
        this.setState({
            id: '',
            image: 'https://oa.haosen.com.cn:8644/img/notimage.jpg',
            title: '',
            disabled: true,
            detail: '',
            selectedRows:[]
        })
    }

    delete = () => {
        const data = {
            token: this.props.parent.state.token,
            location: this.props.parent.state.location,
            id: this.state.id
        }
        Modal.confirm({
            title: 'Confirm',

            content: '确认删除吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                fetch(path + 'Template/deleteTemplate', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    body: JSON.stringify({data}),
                    mode: 'cors',
                    cache: 'default'
                })
                    .then(res => res.json()
                    )
                    .then((data) => {

                        let result = JSON.parse(data)
                        // console.log(result)
                        if(result.message==="超出授权时间！")
                        {
                            message.error(result.message)
                            let url = window.location.protocol + "//" + window.location.host + '/login.html';
                            window.location.replace(url);
                            return;
                        }
                        notification['info']({
                            message: '提示',
                            description:
                            result.message
                        });
                        this.loadTemplate()
                        this.reset()
                    })
            },
        });
    }


    saveMent = () => {
        if (this.state.title === '') {
            notification['info']({
                message: '提示',
                description:
                    '菜品名称必填！',
            });
            return;
        }
        if (this.state.detail === '') {
            notification['info']({
                message: '提示',
                description:
                    '菜品配方必填！',
            });
            return;
        }

        const data = {
            token: this.props.parent.state.token,
            location: this.props.parent.state.location,
            id: this.state.id,
            image: this.state.image,

            title: this.state.title,
            detail: this.state.detail,
        }
        fetch(path + 'Template/saveTemplate', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({data}),
            mode: 'cors',
            cache: 'default'
        })
            .then(res => res.json()
            )
            .then((data) => {
              //  console.log(data)
                let result = JSON.parse(data)
                // alert(result.message);
                if(result.message==="超出授权时间！")
                {
                    message.error(result.message)
                    let url = window.location.protocol + "//" + window.location.host + '/login.html';
                    window.location.replace(url);
                    return;
                }
                notification['info']({
                    message: '提示',
                    description:
                    result.message
                });
                this.loadTemplate()
            })

    }

    titlelChange = (e) => {
        // console.log(e.target.value)
        this.setState({
            title: e.target.value
        })
    }

    detailChange = (e) => {
        // console.log(e.target.value)
        this.setState({
            detail: e.target.value
        })
    }

    beforeUpload = (file) => {
        return new Promise((resolve, reject) => {
            const {id} = this.state
            // console.log(Num)
            if (id === null || id === '') {
                message.error('请选择菜品!');
                return
            }
            const isJpgOrPng = file.type === 'image/jpeg';
            if (!isJpgOrPng) {
                message.error('只能上传JPG文件!');
                return reject(false)
            }

            const isLt2M = file.size / 1024 <= 30;
            if (!isLt2M) {
                message.error('单个图片不能大于30KB!');
                return reject(false)
            }
            const r = resolve(isJpgOrPng && isLt2M)

            return r;
        });
    }

    handleChange = (e) => {

        if (e.file.status === 'done') {
            this.setState({
                image: e.file.response
            })
            this.loadTemplate()
            this.reset()
        }
    }
    selectChange= (val, selectedRows) =>{ // 表格单选
      //  console.log(val,selectedRows)
        this.rowSelection.selectedRowKeys = val //  要在这里给selectedRowKeys赋值，否则页面不显示选中状态
        this.setState({
            selectedRows:selectedRows[0]
        })
    }

    render() {
        const {TextArea} = Input
        const columns = [
            {
                title: '菜品编号',
                dataIndex: 'id',
                key: 'id',
                render: (text: string) => <a>{text}</a>,
            },
            {
                title: '菜品名称',
                dataIndex: 'title',
                key: 'title',
            }, {
                title: '发布食堂',
                dataIndex: 'location',
                key: 'location',
            }, {
                title: '菜品配方',
                dataIndex: 'detail',
                key: 'detail',
            }, {
                title: '菜品图片',
                dataIndex: 'image',
                key: 'image',
                render: (record) => <img src={record} alt="" width="100px"/>
            },
        ]
        const rowRadioSelection = {
            type: 'radio',
            columnTitle: "选择",
            onSelect: (selectedRowKeys, selectedRows) => {
                console.log(selectedRowKeys)
                console.log(selectedRowKeys, selectedRows)
            },

        }

        return (
            <div>
                <Divider orientation="left" plain>
                    维护订餐模板
                </Divider>
                <Row>
                    <Col span={6}>
                        <Form.Item
                            label="菜品编号"

                            rules={[{required: true, message: '发布食堂!'}]}
                        >
                            <Input value={this.state.id} disabled
                                   defaultValue={this.state.id}
                                   style={{width: 180}}/>
                        </Form.Item>
                        <Form.Item
                            label="菜品名称"
                            // name="title"
                            rules={[{required: true, message: '请输入餐名!'}]}
                        >
                            <Input value={this.state.title} onChange={this.titlelChange}
                                   style={{width: 180}} placeholder="请输入餐名"/>
                        </Form.Item>
                        <Form.Item
                            label="发布食堂"
                            // name="location"
                            rules={[{required: true, message: '发布食堂!'}]}
                        >
                            <Input value={this.state.location} disabled
                                   defaultValue={this.state.location}
                                   style={{width: 180}}/>
                        </Form.Item>
                        <Form.Item
                            label="菜品配方"
                            // name="detail"
                            rules={[{required: true, message: '发布食堂!'}]}
                        >
                            <TextArea rows={5} value={this.state.detail} onChange={this.detailChange}
                                      placeholder="请输入菜单配方" style={{width: 180}}/>

                        </Form.Item>
                        <Form.Item
                            label="菜品图片"
                        >
                            <Image
                                width={190}
                                src={this.state.image}
                            />
                        </Form.Item>
                        < Space>
                            <Upload
                                // name="avatar"
                                //  listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                data={{id: this.state.id}}
                                action={path + 'Template/UploadFileData'}
                                beforeUpload={this.beforeUpload}
                                onChange={this.handleChange}
                            >
                                <Button type="primary" disabled={this.state.disabled}>选择图片</Button>
                            </Upload>
                            <Button type="primary" onClick={this.saveMent}>提交保存</Button>
                        </Space>
                        <div style={{'white-space': 'pre-wrap'}}> {`\n`}</div>
                        < Space>
                            <Button type="primary" onClick={this.reset}>重置内容</Button>

                            <Button type="primary" onClick={this.delete} disabled={this.state.disabled}>删除模板</Button>
                        </Space>
                    </Col>
                    <Col span={18}>
                        <Table dataSource={this.state.dataSource} columns={columns}
                               rowSelection= {{
                                   type:'radio',
                                   onChange: this.selectChange,
                                 }}
                               pagination={{pageSize: 5}}
                               rowKey={record => record.id}
                               onRow={record => {
                                   return {
                                       onClick: event  => {

                                          if (event.target.parentNode.cellIndex === 1) {
                                               this.menuonClickRow(record)
                                        }
                                       }, // 点击行
                                       onDoubleClick: event => {
                                       },
                                       onContextMenu: event => {
                                       },
                                       onMouseEnter: event => {
                                       }, // 鼠标移入行
                                       onMouseLeave: event => {
                                       },
                                   };
                               }}
                        />
                    </Col>
                </Row>

            </div>
        );
    }
}